<template>
  <div class="part__mobile-price_wrapper">
    <div class="part__mobile-price_container">
      <div>
        <div class="part__price_name_container">
          <div v-if="item.logo">
            <img :src="item.logo" class="part__price_company_logo" />
          </div>
          <div class="part__price_company_name_container">
            <div
              v-if="item.name"
              class="part__price_company_name"
              @click="openModalAboutCompany">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div>
          <div v-if="item.address">
            <div>{{part.place[language]}}</div>
            <div class="part__price_company_place">
              <div>
              <span>
                <b style="font-weight: 600">
                  {{ item.address }}
                </b>
              </span>
              </div>
              <div :style="{paddingLeft: '7px'}">
                <Point />
              </div>
            </div>
          </div>
          <div>
            <div class="block block__align-center">
              <Stars :rating="item.mark" size="small" />
              <div class="part__price_rating">{{item.mark}}</div>
            </div>
          </div>
        </div>
        <div class="part__mobile-price_manuf-container">
          <Carousel v-if="width >= m_lg && !detailsIsOpen" :images="item.imgs"/>
        </div>
      </div>
    </div>
    <div v-show="detailsIsOpen" class="component__padding">
      <div class="block block__center">
        <Carousel :images="item.imgs" :style="{maxWidth: '300px'}"/>
      </div>
      <div class="block block__between">
        <div>
          <div class="part__price_params_container">
              <span>{{ part.method[language] }}
                <b style="font-weight: 600">{{ item.technology }}</b>
              </span>
          </div>
          <div class="part__price_params_container">
              <span>{{ part.technology[language] }}
                <b style="font-weight: 600">{{ item.method }}</b>
              </span>
          </div>
          <div class="part__price_params_container">
              <span>{{ part.time[language] }}
                <b style="font-weight: 600">{{ item.time }}</b>
              </span>
          </div>
          <div class="part__price_params_container">
              <span>{{ part.amount_materials[language] }}
                <b style="font-weight: 600">
                  {{ item.amount + ` ${part.unit[language]}` }}
                </b>
              </span>
          </div>
        </div>
        <div class="part__mobile_discount" v-if="item.discount">
          <Discount />
          <div class="part__price_discount_title">
            {{ part.discount[language] }}
          </div>
          <div class="part__mobile_discount_value">
            {{ `${item.discount}%` }}
          </div>
        </div>
      </div>
      <div class="block block__center part__mobile_close" @click="openDetails">
        {{ part.card_hide_details[language] }}
      </div>
    </div>
    <div class="part__mobile-price_container part__mobile-price_container_money">
      <div v-if="item.priceForOne">
        <div class="part__mobile-price_container_money_title">
          {{ part.price_for_one[language] }}
        </div>
        <div class="part__mobile-price_container_money_value-one"
             :style="setTextSize(func.getMoney(item.price, currency, language), 'priceForOne')">
          {{func.getMoney(item.priceForOne, currency, language)}}
        </div>
      </div>
      <div v-if="item.price">
        <div class="part__mobile-price_container_money_title">
          {{part.full_price[language]}}
        </div>
        <div class="part__mobile-price_container_money_value-all"
             :style="setTextSize(func.getMoney(item.price, currency, language), 'price')">
          {{func.getMoney(item.price, currency, language)}}
        </div>
      </div>
    </div>
    <div class="part__mobile-price_container part__mobile-price_container_details"
         v-if="!detailsIsOpen"
         @click="openDetails"
    >
      <div class="part__mobile-price_container_details_text">
        {{part.card_details[language]}}
      </div>
    </div>
    <div class="part__mobile-price_container part__mobile-price_container_basket">
      <div class="part__mobile-price_container_basket_text" @click="pushToBasket">
        {{part.basket[language]}}
      </div>
    </div>
  </div>
</template>

<script>
import part from '@/assets/text/part.json';
import func from '@/utilites/functions';
import { query } from '@/utilites/axios';

const Point = () => import('@/assets/pictures/part/card/Marker.svg');
const Carousel = () => import('@/components/carousel/Carousel.vue');
const Stars = () => import('@/components/stars/Stars.vue');
const Discount = () => import('@/assets/pictures/part/card/Discount.svg');

export default {
  name: 'PriceMobileCard',
  data() {
    return {
      part,
      func,
      detailsIsOpen: false,
    };
  },
  props: {
    item: Object,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    partData() {
      return this.$store.state.part.partData;
    },
    width() {
      return this.$store.state.index.width;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    currency() {
      const userParameters = this.$store.state.part.userParameters;
      if (userParameters && userParameters.currency) {
        return userParameters.currency;
      } else {
        return 'RUB';
      }
    },
  },
  methods: {
    pushToBasket() {
      const secretKey = localStorage.getItem('token');
      if (secretKey) {
        query('post', 'getPricesV2', {
          order: true,
          time_stamp: this.$store.state.part.timeStamp,
          file_name: this.$store.state.part.fileName,
          hash: this.$store.state.part.hash,
          amount: this.item.amount,
          id_method: this.item.id_method,
          id_manuf: this.item.id_manuf,
          currency: this.currency,
          printing: {
            density: 50,
            wall: 0.6,
            layerMin: 0.3,
            layerMax: 0.5,
          },
        }, {
          token: localStorage.getItem('token'),
          lang: this.language,
        }).then((item) => {
          this.$store.commit('basket/setOrder', item.manuf);
          this.$router.push('basket');
        });
      } else {
        this.$store.commit('user/setAfterAuthorize', this.pushToBasket);
        this.$store.commit('user/setShowAuthorizeModal', true);
      }
    },
    openModalAboutCompany() {
      this.$emit('openAboutModal', this.item);
    },
    openDetails() {
      this.detailsIsOpen = !this.detailsIsOpen;
    },
    setTextSize(value, type) {
      let result = {};
      const paramsCalc = (length, symbolWidth, fontSize, lineHeight) => {
        if (this.width < symbolWidth * length) {
          const fs = Math.round((fontSize * (this.width / length)) / symbolWidth);
          const lh = Math.round((lineHeight * (this.width / length)) / symbolWidth);
          return {
            fontSize: `${fs}px`,
            lineHeight: `${lh}px`,
          };
        } else {
          return {};
        }
      };
      if (type === 'price') {
        const symbolWidth = 25;
        const fontSize = 36;
        const lineHeight = 44;
        result = paramsCalc(value.length, symbolWidth, fontSize, lineHeight);
      } else if (type === 'priceForOne') {
        const symbolWidth = 19;
        const fontSize = 27;
        const lineHeight = 33;
        result = paramsCalc(value.length, symbolWidth, fontSize, lineHeight);
      }
      return result;
    },
  },
  components: {
    Point,
    Carousel,
    Stars,
    Discount,
  },
};
</script>
